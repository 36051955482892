<template>
  <div>
    <!-- Filter -->
    <v-toolbar
      flat
      dense
      color="accent"
    >
      <!--  Filter Icon -->
      <v-menu
        v-if="section !== 14"
        v-model="filterMenu"
        offset-y
        right
        :close-on-content-click="false"
      >
        <template #activator="{ on: menu }">
          <v-tooltip
            right
            color="primary"
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon
                  v-if="showFiltered()"
                  color="primary"
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  color="primary"
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip>
        </template>

        <v-card width="400px">
          <v-card-title>
            <v-spacer />
            <v-btn
              v-if="showFiltered()"
              text
              rounded
              outlined
              block
              @click="clearFilter()"
            >
              <v-icon
                small
                left
              >
                fal fa-times
              </v-icon>Clear Filters
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row
                v-if="section === 11"
                dense
              >
                <!--Code-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="code"
                    label="Code"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--User Type-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="flag_user_type"
                    :items="valueListsStore.userTypes"
                    label="User Type"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="flag_status"
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mx-4 pb-4">
            <v-btn
              block
              rounded
              color="primary"
              @click="filter()"
            >
              <v-icon
                small
                left
              >
                fal fa-check
              </v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <!--Pagination-->
      <pagination
        :show="participationStore.participation.data && participationStore.participation.data.length > 0 && participationStore.participation.last_page > 1 ? true : false"
        :current-page="participationStore.participation.current_page"
        :last-page="participationStore.participation.last_page"
        @paginate-event="filter($event)"
      />

      <v-spacer />

      <participation-entry
        :add-new="0"
        :section="section"
      />

      <profile-entry :section="3" />
    </v-toolbar>
    
    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="participationStore.participation.data && participationStore.participation.data.length > 0 ? true : false"
        :list-data="participationStore.participation.data"
        :title="'Participation'"
        :section="this.section === 11 ? 26 : Number(26 + '.' + section)"
        :button-title="'Participation'"
        :actions="actions"
        @open-entry="openEntry($event)"
        @open-profile="openProfile($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import { createHelpers } from 'vuex-map-fields';
import Pagination from '@/components/Pagination';
import ListBasic from '@/components/ListBasic';
import ParticipationEntry from '@/components/ParticipationEntry';
import ProfileEntry from '@/components/ProfileEntry';


const { mapFields } = createHelpers({
  getterType: 'participationStore/getFilterField',
  mutationType: 'participationStore/updateFilterField',
});

export default {
  name: 'Participation',
  components: {
    Pagination,
    ListBasic,
    ParticipationEntry,
    ProfileEntry
  },
  mixins: [mixin],
   props: {
     /*11 = Settings User Detail, 12 = Settings Project Detail, 13 = User Detail Project*/
    section: Number,
  },
  data() {
    return {
      filterMenu: false,
      actions: {
        menu: 1,
        view: 0,
        edit: 1,
        password:0,
        invite:0,
        email:0
      },
    };
  },
  computed: {
    ...mapState({
      progressStore: state => state.progressStore,
      participationStore: state => state.participationStore,
      valueListsStore: state => state.valueListsStore,
    }),
    ...mapFields([
      'page',
      'sectionStored',

      'id_user',
      'id_project',
      'code',
      'flag_user_type',
      'flag_status',
    ]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {

      this.$store.dispatch('progressStore/set',true)

      this.changeSectionCheck();

      /*Settings User Detail*/
      if(this.section === 11) {
        let user = this.$store.getters['usersStore/getUser'];
        this.id_user = user.id
        this.id_project = ''
      }
      /*Settings Project Detail*/
      else if(this.section === 12) {
        let project = this.$store.getters['projectsStore/getProject'];
        this.id_user = ''
        this.id_project = project.id
      }
      /*Project User Detail*/
      else if(this.section === 13) {
        let user = this.$store.getters['usersStore/getUser'];
        let project = this.$store.getters['usersStore/getActiveProject'];
        this.id_user = user.id
        this.id_project = project.id
      }
      /*Profile*/
      else if(this.section === 14) {
        let user = this.$store.getters['usersStore/getActiveUser'];
        let project = this.$store.getters['usersStore/getActiveProject'];
        this.id_user = user.id
        this.id_project = project.id
        this.flag_status = 0;
      }
      else {
        this.id_user = ''
        this.id_project = ''
      }

      this.sectionStored = this.section;

	    /* Grab filters */
      let filters = this.$store.getters['participationStore/getFilters'];
      
      /* Grab participation based on active filters */
      await this.$store.dispatch('participationStore/fetch', filters);
      this.$store.dispatch('progressStore/set',false)
    },

	  async filter(page) {
      this.filterMenu = false;
      this.page = page;
		  this.initialize();
    },
    
    clearFilterColumns() {
      this.code = '';
      this.flag_status = '';
      this.flag_user_type = '';
      this.page = 1;
    },

	  async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
		  this.initialize();
    },
    
    async changeSectionCheck() {
      if(this.sectionStored !== this.section) {
        this.clearFilterColumns();
        await this.$store.dispatch('participationStore/clearParticipation');
      }
    },

	  async openEntry(id) {
      this.$store.dispatch('participantsStore/clearParticipantsSelected');
      let project;
      /*Settings User Detail*/
      if(this.section === 11) {
        await this.$store.dispatch('participationStore/read', id)
        let projectuserjoin = this.$store.getters['participationStore/getRecord']
        await this.$store.dispatch('projectsStore/read',projectuserjoin.id_project)
        project = this.$store.getters['projectsStore/getProject']
      }
      /*Settings Project User Detail*/
      else if(this.section === 12) {
        project = this.$store.getters['projectsStore/getProject']
      }
      /*User Detail*/
      else if(this.section === 13 || this.section === 14) {
        project = this.$store.getters['usersStore/getActiveProject']
      }
      let yearStart = this.$_formatDateYear(project.date_start)
      let data = {
        yearStart : yearStart
      }
      await this.$store.dispatch('valueListsStore/setProjectYearList',data)
      const researcherData = {
        id_project: project.id,
        type: 1
      }
      await this.$store.dispatch('participantsStore/fetch', researcherData);

      await this.$store.dispatch('participationStore/entry', id);

      const advisors = this.$store.getters['participationStore/getAdvisors'];

      let newAdvisors = []
      for (var i = 0; i < advisors.length; i++) {
        if(advisors[i].pivot.id_project === project.id) {
          const idx = newAdvisors.map(item => item.id).indexOf(advisors[i].id);
          if(idx < 0) {
            newAdvisors.push(advisors[i])
          }
        }
      }

      await this.$store.dispatch('participantsStore/setParticipants', newAdvisors);
    },

    async openProfile(id) {
      await this.$store.dispatch('participationStore/read', id)
      let projectuserjoin = this.$store.getters['participationStore/getRecord']
      await this.$store.dispatch('projectsStore/read',projectuserjoin.id_project)
      let project = this.$store.getters['projectsStore/getProject']
      let yearStart = this.$_formatDateYear(project.date_start)
      let year = {
        yearStart : yearStart
      }
      await this.$store.dispatch('valueListsStore/setProjectYearList',year)
      let data = {
        id_project: project.id,
        id_jurisdiction: projectuserjoin.id_jurisdiction,
        id_institution: projectuserjoin.id_institution
      }
      await this.$store.dispatch('jurisdictionsStore/byProject', data);
      await this.$store.dispatch('institutionsStore/byProject', data);
      await this.$store.dispatch('departmentsStore/byProject', data)
      let profile = {
        id: id,
      }
      await this.$store.dispatch('usersStore/getProfile', profile)
      this.$store.dispatch('usersStore/openProfile')
    },
    
    showFiltered(){
      if(this.code || this.flag_user_type || this.flag_status === 0 || this.flag_status === 1) {
        return true
      }
    },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
