<template>
  <v-col
    v-if="progress !== true"
    cols="12"
    sm="12"
  >
    <v-toolbar
      class="pl-0"
      flat
      dense
      color="accent"
    >
      <v-spacer />

      <profile-entry
        v-if="activeProfile === true"
        :section="2"
      />
    </v-toolbar>

    <v-row dense>
      <title-bar
        :title-value="usersStore.activeUser.name"
        :subtitle-value="usersStore.activeUser.email"
        :reduce-x="1"
      />
    </v-row>

    <v-container
      v-if="activeProfile === true"
      fluid
    >
      <v-row dense>
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <!--User Type-->
          <text-display
            :display-string="$_getUserType(usersStore.activeUserType)"
            :label="'User Type'"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <!--Start Date-->
          <text-display
            :display-string="usersStore.userProfile.date_start_month + ' ' + usersStore.userProfile.date_start_year"
            :label="'Participation Start'"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <!--Title-->
          <text-display
            :display-string="usersStore.userProfile.title_other ? usersStore.userProfile.title_other : usersStore.userProfile.title"
            :label="'Title'"
          />
        </v-col>

        <v-col
          v-if="usersStore.authUserType === 1 || usersStore.authUserType === 2 && usersStore.activeUserType === 3 || usersStore.authUserType === 3 || usersStore.authUserType === 4 || usersStore.authUserType === 5 || usersStore.authUserType === 6 || usersStore.authUserType === 7"
          cols="12"
          sm="12"
          md="4"
        >
          <!--Classification-->
          <text-display
            :display-string="usersStore.userProfile.classification_other ? usersStore.userProfile.classification_other : usersStore.userProfile.classification"
            :label="'Classification'"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          cols="12"
          sm="12"
        >
          <!--ORCID ID-->
          <text-display
            :display-string="usersStore.userProfile.orcid_id"
            :label="'ORCID ID'"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          cols="12"
          sm="12"
        >
          <!--Phone-->
          <text-display
            :display-string="usersStore.userProfile.phone"
            :label="'Phone'"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          cols="12"
          sm="12"
        >
          <!--Jurisdiction-->
          <text-display
            :display-string="usersStore.userProfile.jurisdiction ? usersStore.userProfile.jurisdiction.name : ''"
            :label="'EPSCoR Jurisdiction'"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <!--Other Institution-->
          <text-display
            v-if="usersStore.userProfile.institution_other"
            :display-string="usersStore.userProfile.institution_other"
            :label="'Institution'"
          />
          <!--Institution-->
          <text-display
            v-else
            :display-string="usersStore.userProfile.institution ? usersStore.userProfile.institution.name : ''"
            :label="'Institution'"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <!--Other Department-->
          <text-display
            v-if="usersStore.userProfile.department_other"
            :display-string="usersStore.userProfile.department_other "
            :label="'Department'"
          />
          <!--Department-->
          <text-display
            v-else
            :display-string="usersStore.userProfile.department ? usersStore.userProfile.department.name : ''"
            :label="'Department'"
          />
        </v-col>
      </v-row>

      <!--------------------START DEMOGRAPHICS-------------------->
      <v-row
        v-if="usersStore.authUser.id === usersStore.userProfile.id_user"
        dense
      >
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <!--Gender-->
          <text-display
            :display-string="usersStore.userProfile.gender"
            :label="'Gender'"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <!--Race-->
          <text-display
            :display-array="usersStore.userProfile.race"
            :label="'Race'"
          />
        </v-col>
      </v-row>

      <v-row
        v-if="usersStore.authUser.id === usersStore.userProfile.id_user"
        dense
      >
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <!--Ethnicity-->
          <text-display
            :display-string="usersStore.userProfile.ethnicity"
            :label="'Ethnicity'"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <!--Disability-->
          <text-display
            :display-string="usersStore.userProfile.disability"
            :label="'Disability'"
          />
        </v-col>
      </v-row>
      <!--------------------END DEMOGRAPHICS-------------------->

      <v-row
        v-if="usersStore.userProfile.classification === 'Graduate Student' || usersStore.userProfile.classification === 'Undergraduate'"
        dense
      >
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <!--Degree being pursued-->
          <text-display
            :display-string="usersStore.userProfile.degree"
            :label="'Degree Being Pursued'"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="2"
        >
          <!--Degree Earned-->
          <text-display
            :display-string="usersStore.userProfile.degree_earned"
            :label="'Degree Earned'"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="2"
        >
          <!--Degree Earned-->
          <text-display
            :display-string="usersStore.userProfile.degree_month || usersStore.userProfile.degree_year ? usersStore.userProfile.degree_month + ' ' + usersStore.userProfile.degree_year : ''"
            :label="'Date Earned'"
          />
        </v-col>
      </v-row>
      
      <!--Previous Participation-->
      <v-row dense />
    </v-container>

    <v-tabs
      v-if="inactiveProfile === true"
      background-color="transparent"
    >
      <v-tabs-slider light />
      
      <v-tab
        :key="1"
        :href="'#tab-1'"
      >
        Participation History
      </v-tab>

      <v-tab-item
        :key="1"
        :value="'tab-1'"
      >
        <v-card
          flat
          tile
        >
          <participation :section="14" />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import TextDisplay from '@/components/TextDisplay';
import ProfileEntry from '@/components/ProfileEntry';
import Participation from '@/components/Participation';

export default {
  name: 'Profile',
  components: {
    TitleBar,
    TextDisplay,
    ProfileEntry,
    Participation,
  },
  mixins: [mixin],
  data() {
	  return {
      progress: false,
      activeProfile:'',
      inactiveProfile: '',
	  };
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      progressStore: state => state.progressStore,  
    }),
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      this.progress = true
      let activeUser = this.$store.getters['usersStore/getActiveUser']
      let activeUserType = this.$store.getters['usersStore/getActiveUserType'];
      let activeProject = this.$store.getters['usersStore/getActiveProject']
      let profileID
      for (let i = 0; i < activeUser.participation_active.length; i++) {
        if(activeUser.participation_active[i].id_project === activeProject.id && activeUser.participation_active[i].flag_user_type === activeUserType) {
          profileID = activeUser.participation_active[i].id
        }
      }
      if(profileID) {
        this.activeProfile = true
        let profile = {
          id: profileID,
        }
        await this.$store.dispatch('usersStore/readProfile', profile)
      }
      if(activeUser.participation_inactive.length > 0) {
        this.inactiveProfile = true
      }
      this.progress = false
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
